.slider {
  padding: 0 7px;

  .values {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -7px;
  }
}

.prefix,
.suffix {
  font-weight: 400;
}

.buttonList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > * {
    @media (--md-down) {
      flex-grow: 1;
    }
  }

  .btnRoot {
    padding: 9px 15px;
    min-width: 50px;
  }
}

.shapeList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-family: var(--font-secondary);

  .btn {
    color: inherit;
    text-align: center;
    padding: 0 10px;
    min-width: 100px;

    @media (--md-up) {
      width: 100px;
    }

    @media (--md-down) {
      width: 20%;
    }

    @media (--sm-down) {
      width: 33.333%;
    }

    &.active {
      .name {
        &::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .name {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;

    @media (--sm-down) {
      font-size: 13px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      border-bottom: 1px solid currentcolor;
      transform: translateY(5px);
      opacity: 0;
      transition: all ease 0.25s;
    }
  }

  .icon {
    font-size: 60px;
    margin-bottom: 20px;

    @media (--md-down) {
      font-size: 50px;
      margin-bottom: 15px;
    }

    @media (--sm-down) {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
}
