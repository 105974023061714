.list {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;

  .item:nth-child(n + 7) {
    display: none;
  }

  @media (--xxl-down) {
    .item:nth-child(n + 6) {
      display: none;
    }
  }

  @media (--xl-down) {
    .item:nth-child(n + 5) {
      display: none;
    }
  }

  @media (--lg-down) {
    .item:nth-child(n + 4) {
      display: none;
    }
  }

  .item {
    width: 11.5%;

    @media (--xxl-down) {
      width: calc(100% / 5);
    }

    @media (--xl-down) {
      width: calc(100% / 4);
    }

    @media (--lg-down) {
      width: calc(100% / 3);
    }

    @media (--sm-down) {
      width: calc((100% - 100px) / 3);
    }
  }
}
