.slide {
  color: var(--primary-main);
  font-family: var(--font-secondary);
  text-align: center;
  display: block;

  .imgWrap {
    font-size: 140px;
    width: 1em;
    height: 1em;
    margin: 0 auto 10px;
    position: relative;

    &:hover {
      img {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    }

    @media (--sm-down) {
      width: 100%;
      height: 110px;
    }

    img,
    svg {
      object-fit: contain;
      transition: all ease 0.5s;
    }

    img {
      @media (--md-up) {
        opacity: 0;
      }
    }

    svg {
      @media (--md-down) {
        opacity: 0;
      }
    }
  }

  .name {
    position: relative;
    padding-bottom: 10px;
    display: inline-block;

    @media (--sm-down) {
      line-height: 1.2;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      transition: all ease 0.25s;
      border-bottom: 1px solid currentcolor;
      opacity: 0;
      transform: translateY(5px);
    }
  }
}
