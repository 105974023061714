.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;

  svg {
    width: 100%;
    height: 100%;
  }
}

.label {
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 12px;
  }
}

.skeleton {
  width: 1em;
  height: 1em;
  background-color: var(--grey-300);
}
