.wrap {
  color: var(--secondary-contrastText);

  .itemCardWrap {
    padding: 0 !important;
  }

  .tableBodyRoot {
    cursor: pointer;
  }

  td,
  th {
    color: var(--secondary-contrastText);
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px;

    @media (--md-down) {
      padding: 2px;
      text-align: center;
    }

    &:first-child {
      padding-left: 8px;

      @media (--xxl-up) {
        padding-left: 90px;
      }

      @media (--md-up) {
        padding-left: 30px;
      }
    }

    &:last-child {
      padding-right: 8px;

      @media (--xxl-up) {
        padding-right: 90px;
      }

      @media (--md-up) {
        padding-right: 30px;
      }
    }
  }

  th {
    font-size: 16px;

    @media (--md-down) {
      font-size: 10px;
      line-height: 1.2;
    }
  }

  td {
    font-size: 20px;

    @media (--md-down) {
      font-size: 10px;
    }

    @media (--sm-down) {
      word-break: break-word;
    }
  }

  .btnWrapCell {
    text-align: center;

    @media (--md-up) {
      padding: 9px 16px;
    }
  }

  .shape {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      @media (--md-down) {
        width: 26px;
        height: 26px;
      }
    }
  }

  .active {
    background: var(--secondary-main);

    &:hover {
      background: var(--secondary-main);
    }

    td {
      border-color: var(--secondary-main);
    }
  }
}

.hideLgDown {
  @media (--lg-down) {
    display: none !important;
  }
}

.hideMdDown {
  @media (--md-down) {
    display: none !important;
  }
}

.sortBtn {
  position: relative;
  padding-right: 20px;

  @media (--md-down) {
    padding-right: 10px;
  }
}

.chevron {
  rotate: 180deg;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 50%;
  translate: 0 -50%;

  &.selected {
    opacity: 1;
  }

  &.up {
    rotate: 0deg;
  }
}
