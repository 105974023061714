.wrap {
  padding: 86px 0 45px;

  @media (--md-down) {
    text-align: center;
    padding: 60px 0 30px;
  }

  h2 {
    @media (--md-up) {
      text-align: right;
    }
  }
}

.tab {
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  padding: 10px 20px 20px;

  @media (--sm-down) {
    font-size: 13px;
    margin-top: 8px;
    padding: 10px 20px 18px;
  }

  + .tab {
    @media (--md-up) {
      margin-left: 20px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid currentcolor;
    opacity: 0;
    transform: translateY(5px);
    transition: all ease 0.25s;
  }

  &.active {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.sliderWrap {
  position: relative;

  @media (--md-up) {
    padding: 0 70px;
  }

  .next,
  .prev {
    position: absolute;
    top: 50px;
    display: flex;
    align-items: center;

    @media (--md-down) {
      display: none;
    }
  }
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.slider {
  margin: 67px auto 0;
  max-width: 1440px;

  @media (--sm-down) {
    margin-top: 0;
  }
}

.btnWrap {
  text-align: center;
  margin-top: 38px;

  @media (--sm-down) {
    margin-top: 15px;
  }
}
