.button {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &.open {
    font-weight: 500;

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    transition: all ease 0.25s;
    margin-left: 13px;

    @media (--sm-down) {
      margin-left: 5px;
    }
  }
}
