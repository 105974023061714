.wrap {
  display: flex;
  align-items: center;

  @media (--sm-up) {
    margin-right: 25px;
  }
}

.label {
  text-transform: uppercase;
  color: var(--grey-500);
}
