.wrap {
  background: var(--secondary-light);
  color: var(--secondary-contrastText);
  padding: 50px 0;
  font-size: 15px;
}

.inner {
  display: flex;

  @media (--lg-down) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.buttons {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;

  @media (--lg-down) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.imgWrap {
  margin-right: 112px;
  max-width: 330px;
  height: 330px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-main);
  position: relative;

  @media (--xl-down) {
    margin-right: 20px;
  }

  @media (--lg-down) {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .warning {
    font-family: var(--font-secondary);
    text-align: center;
    padding: 20px;
  }

  .hint {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font-secondary);
  }
}

.details {
  flex-grow: 1;
}

.mainInfo {
  display: flex;
  justify-content: space-between;

  @media (--xl-down) {
    flex-wrap: wrap;
  }

  .link {
    @media (--lg-down) {
      width: 100%;
    }
  }

  .name {
    font-family: var(--font-secondary);
    font-size: 32px;
    color: var(--secondary-contrastText);

    @media (--lg-down) {
      width: 100%;
      text-align: center;
    }

    @media (--md-down) {
      font-size: 20px;
    }
  }
}

.byuButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (--lg-down) {
    margin-top: 20px;
    width: 100%;
  }
}

.additionalInfo {
  max-width: 854px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @media (--md-down) {
    display: block;
  }

  .cell {
    td {
      font-size: 15px;
      padding: 11px;
      border-color: var(--grey-50);

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        text-align: right;
        font-weight: 500;
        padding-right: 0;
      }
    }
  }

  .linksWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column: span 2;

    .linkWrap {
      text-transform: uppercase;
      font-weight: 500;

      @media (--md-down) {
        margin-top: 20px;
      }
    }
  }
}
